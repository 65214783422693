import basicFlow from "./asyncHandler";
import { put, select, takeEvery } from "redux-saga/effects";
import { actions, types } from "../reducers/painelAlerta.actions";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import {
  getFiltros,
  getFiltrosChart,
  getFiltrosTop,
} from "../selectors/painelAlerta.selectors";
import formatters from "../utils/formatters";
import { getUsuario } from "../selectors/usuario.selectors";

const pesquisar = basicFlow({
  actionGenerator: actions.pesquisar,
  transform: function* () {
    const currentFilter = yield select(getFiltros);
    const usuario = yield select(getUsuario);
    const empresa = currentFilter.empresa?.id
      ? currentFilter.empresa?.id
      : !!usuario?.empresa
      ? usuario.empresa
      : currentFilter.empresa?.id;
    return {
      ...currentFilter,
      empresa,
      inicioPeriodo: formatters.dates.revert(currentFilter.inicioPeriodo),
      fimPeriodo: formatters.dates.revert(currentFilter.fimPeriodo),
      dispositivo: currentFilter.dispositivo?.id,
      tipoAlerta: currentFilter.tipoAlerta?.id,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/alerta/alertas-diarios/listar`,
      method: "post",
      body: values,
    });
  },
});

const pesquisarTopAlertas = basicFlow({
  actionGenerator: actions.pesquisarTop,
  transform: function* () {
    const currentFilter = yield select(getFiltrosTop);
    const usuario = yield select(getUsuario);
    const empresa = currentFilter.empresa?.id
      ? currentFilter.empresa?.id
      : !!usuario?.empresa
      ? usuario.empresa
      : currentFilter.empresa?.id;
    return {
      ...currentFilter,
      empresa,
      dispositivo: currentFilter.dispositivo?.id,
      tipoAlerta: currentFilter.tipoAlerta?.id,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/alerta/alertas-diarios/listar-top-alertas`,
      method: "post",
      body: values,
    });
  },
});

const pesquisarChartAlertas = basicFlow({
  actionGenerator: actions.pesquisarChart,
  transform: function* () {
    const currentFilter = yield select(getFiltrosChart);
    const usuario = yield select(getUsuario);
    const empresa = currentFilter.empresa?.id
      ? currentFilter.empresa?.id
      : !!usuario?.empresa
      ? usuario.empresa
      : currentFilter.empresa?.id;
    return {
      ...currentFilter,
      empresa,
      dispositivo: currentFilter.dispositivo?.id,
      tipoAlerta: currentFilter.tipoAlerta?.id,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: `/alerta/alertas-diarios/listar-chart-alertas`,
      method: "post",
      body: values,
    });
  },
});

const carregarEmpresas = basicFlow({
  actionGenerator: actions.carregarEmpresas,
  api: (values) => {
    return authenticatedRequest({
      url: `/cliente/dominio?cliente=${values?.cliente ?? "false"}`,
      method: "get",
    });
  },
});
const carregarDispositivos = basicFlow({
  actionGenerator: actions.carregarDispositivos,
  api: () => {
    return authenticatedRequest({
      url: `/dispositivo/dominio`,
      method: "get",
    });
  },
});

function* atualizarFiltrosWatcher() {
  yield takeEvery([types.ATUALIZAR_FILTROS], function* () {
    yield put(actions.pesquisar.request());
  });
}
function* atualizarFiltrosChartWatcher() {
  yield takeEvery([types.ATUALIZAR_FILTROS_CHART], function* () {
    yield put(actions.pesquisarChart.request());
  });
}
function* atualizarFiltrosTopWatcher() {
  yield takeEvery([types.ATUALIZAR_FILTROS_TOP], function* () {
    yield put(actions.pesquisarTop.request());
  });
}

function* mapRouteWatcher() {
  yield routeWatcher(routes.PAINEL_ALERTAS, function* () {
    const currentFilter = yield select(getFiltros);
    const currentFilterChart = yield select(getFiltrosChart);
    const currentFilterTop = yield select(getFiltrosTop);
    const usuario = yield select(getUsuario);

    if (currentFilter.inicioPeriodo === null) {
      currentFilter.inicioPeriodo = formatters.dates.short(new Date());
      currentFilter.fimPeriodo = formatters.dates.short(new Date());
    }

    if (currentFilterChart.inicioPeriodo === null) {
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      currentFilterChart.inicioPeriodo = formatters.dates.short(new Date());
      currentFilterChart.fimPeriodo = formatters.dates.short(thirtyDaysAgo);
    }

    if (currentFilterTop.referencia === null) {
      currentFilterTop.referencia = { id: "current_month", nome: "Mês atual" };
    }

    yield put(actions.carregarDispositivos.request());

    if (usuario?.empresa) {
      yield put(
        actions.carregarEmpresas.request({ cliente: usuario?.empresa })
      );
      yield put(
        actions.atualizarFiltros({
          ...currentFilter,
          empresa: usuario.empresa,
          empresaNome: usuario.empresaNome,
        })
      );
      yield put(
        actions.atualizarFiltrosTop({
          ...currentFilterTop,
          empresa: usuario.empresa,
          empresaNome: usuario.empresaNome,
        })
      );
      yield put(
        actions.atualizarFiltrosChart({
          ...currentFilterChart,
          empresa: usuario.empresa,
          empresaNome: usuario.empresaNome,
        })
      );
    } else {
      yield put(actions.carregarEmpresas.request());
      yield put(
        actions.atualizarFiltros({
          ...currentFilter,
        })
      );
      yield put(
        actions.atualizarFiltrosTop({
          ...currentFilterTop,
        })
      );
      yield put(
        actions.atualizarFiltrosChart({
          ...currentFilterChart,
        })
      );
    }
  });
}

export const sagas = [
  pesquisar.watcher(),
  carregarEmpresas.watcher(),
  carregarDispositivos.watcher(),
  atualizarFiltrosWatcher(),
  mapRouteWatcher(),

  pesquisarChartAlertas.watcher(),
  atualizarFiltrosChartWatcher(),
  pesquisarTopAlertas.watcher(),
  atualizarFiltrosTopWatcher(),
];
