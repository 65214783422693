import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../components/loaders";
import { actions as mapaActions } from "../../../reducers/mapa.actions";
import { actions } from "../../../reducers/mapaExecucao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { getUsuario } from "../../../selectors/usuario.selectors";
import {
  getFiltros,
  getOrder,
  getSortedItems,
  isLoadingItems,
} from "../../../selectors/mapaExecucao.selectors";
import ExecucaoTable from "./ExecucaoTable";

const ExecucaoTableConnected = ({ mesAno }) => {
  const filtros = useSelector(getFiltros);
  const items = useSelector(getSortedItems);
  const loading = useSelector(isLoadingItems);
  const order = useSelector(getOrder);
  const { perfil } = useSelector(getUsuario);

  const dispatch = useDispatch();

  const handleOrderClick = useCallback(
    (field) => {
      dispatch(actions.atualizarOrdem(field));
    },
    [dispatch]
  );

  const handleGoToMap = (values) => {
    dispatch(mapaActions.alternarBotao());
    dispatch(mapaActions.atualizarSelecionarOs.request({ id: values.id }));
    dispatch(routeActions.redirectTo(routes.MAPA));
  };

  if (loading) {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <Loader />
      </div>
    );
  }

  return (
    <ExecucaoTable
      cliente={
        filtros?.empresa?.nome ? filtros?.empresa?.nome : filtros.empresaNome
      }
      mesAno={filtros.mesAno}
      items={items}
      order={order}
      handleOrderClick={handleOrderClick}
      handleGoToMap={handleGoToMap}
      perfil={perfil}
    />
  );
};

export default ExecucaoTableConnected;
