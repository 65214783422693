import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components/loaders";
import { actions as mapaActions } from "../../../../reducers/mapa.actions";
import { actions } from "../../../../reducers/painelAlerta.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../../reducers/rotas.actions";

import {
  getFiltrosTop,
  isLoadingTopItems,
  getTopItems,
} from "../../../../selectors/painelAlerta.selectors";
import TopAlerts from "./TopAlerts";

const TopAlertsConnected = () => {
  const filtros = useSelector(getFiltrosTop);
  const items = useSelector(getTopItems);
  const loading = useSelector(isLoadingTopItems);

  const dispatch = useDispatch();

  const handleOrderClick = useCallback(
    (field) => {
      dispatch(actions.atualizarOrdem(field));
    },
    [dispatch]
  );

  const handleGoToMap = (values) => {
    dispatch(mapaActions.alternarBotao("alerta"));
    dispatch(mapaActions.atualizarSelecionarOs.request({ id: values }));
    dispatch(routeActions.redirectTo(routes.MAPA));
  };

  if (loading) {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <Loader />
      </div>
    );
  }

  return (
    <TopAlerts
      cliente={
        filtros?.empresa?.nome ? filtros?.empresa?.nome : filtros.empresaNome
      }
      data={items}
      handleOrderClick={handleOrderClick}
      handleGoToMap={handleGoToMap}
    />
  );
};

export default TopAlertsConnected;
