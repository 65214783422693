import { Drawer, Grid, IconButton, styled } from "@mui/material";
import React from "react";
import { transientOptions } from "../../../../styles/theme";
import { Title } from "../../../../components/styled";
import { HighlightOff } from "@mui/icons-material";
import {
  ControlledTextField,
  DateTextField,
} from "../../../../components/inputs";
import { Button } from "../../../../components";
import ControlledAutocomplete from "../../../../components/inputs/ControlledAutocomplete";
import { tipoAlerta } from "../../../../utils/parametros";

const StyledDrawer = styled(Drawer, transientOptions)`
  & .MuiDrawer-paper {
    box-shadow: ${({ theme }) => theme.shadow.map.options};
    max-width: 500px;
    width: 100%;
  }
`;
const TitleWithoutMargins = styled(Title)`
  margin: 0 0 1.5rem 0 !important;
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: ${({ theme }) => theme.colors.dark};
`;

const GradientButton = styled(Button)`
  background: ${({ theme }) => theme.colors.gradients.button};
  border-radius: 100px;
  width: 90%;
  margin-left: 10px;
  margin-bottom: 10px;
`;
const OutlinedButton = styled(Button)`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary._700};
  color: ${({ theme }) => theme.colors.primary._700};
  border-radius: 100px;
  width: 90%;
  margin-left: 10px;
  margin-bottom: 10px;

  &:hover {
    background: transparent;
  }
`;
const StyledGrid = styled(Grid)``;
const StyledBottomContainer = styled(Grid)`
  height: fit-content;
  place-self: flex-end;
`;

const FiltersDrawer = ({
  handleClose,
  visible,
  formProps,
  //  handleChangeEmpresa,
  showEmpresa,
  domainEmpresas,
  domainDispositivos,
  handleSubmit,
}) => {
  return (
    <StyledDrawer onClose={handleClose} open={visible} anchor={"right"}>
      <CloseIconButton onClick={handleClose}>
        <HighlightOff />
      </CloseIconButton>
      <form onSubmit={handleSubmit}>
        <StyledGrid container padding={"1rem"} spacing={"1rem"}>
          <Grid item xs={12}>
            <TitleWithoutMargins>Filtros</TitleWithoutMargins>
          </Grid>
          <Grid item xs={6} lg={6}>
            <ControlledTextField
              name={"inicioPeriodo"}
              formProps={formProps}
              label={"Data Início"}
              InputProps={{
                inputComponent: DateTextField,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledTextField
              name={"fimPeriodo"}
              formProps={formProps}
              label={"Data Fim"}
              InputProps={{
                inputComponent: DateTextField,
              }}
            />
          </Grid>
          {!showEmpresa && domainEmpresas.length === 1 ? (
            <></>
          ) : (
            <Grid item xs={12}>
              <ControlledAutocomplete
                name={"empresa"}
                formProps={formProps}
                label={"Cliente"}
                domain={domainEmpresas.filter(
                  (el) => el.nome !== "TODOS OS CLIENTES DO GRUPO"
                )}
                labelField={"nome"}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ControlledTextField
              name={"roteiro"}
              formProps={formProps}
              label={"Roteiro"}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              name={"dispositivo"}
              formProps={formProps}
              label={"Dispositivo"}
              domain={domainDispositivos}
              labelField={"nome"}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              name={"tipoAlerta"}
              disabled={tipoAlerta.length === 0}
              formProps={formProps}
              label={"Tipo Alerta"}
              domain={tipoAlerta}
              idField={"id"}
              labelField={"nome"}
            />
          </Grid>
        </StyledGrid>
        <StyledBottomContainer container padding={"1rem"} spacing={"1rem"}>
          <Grid item xs={12} md={6}>
            <OutlinedButton onClick={handleClose}>VOLTAR</OutlinedButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <GradientButton type={"submit"}>FILTRAR</GradientButton>
          </Grid>
        </StyledBottomContainer>
      </form>
    </StyledDrawer>
  );
};

export default FiltersDrawer;
