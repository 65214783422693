import React, { useCallback, useEffect } from "react";
import FiltersDrawer from "./FiltersDrawer";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../reducers/mapa.actions";
import {
  getDominioDispositivos,
  getDominioEmpresas,
  getDominioGrupos,
  getDrawer,
  getFiltros,
} from "../../../selectors/mapa.selectors";
import useCompleteForm from "../../../hooks/useCompleteForm";
import { getUsuario } from "../../../selectors/usuario.selectors";
import validators from "../../../utils/validators";
import formatters from "../../../utils/formatters";
import { useState } from "react";

const FilterDrawerConnected = () => {
  const dispatch = useDispatch();
  const { filters: filtersVisible } = useSelector(getDrawer);
  const filtros = useSelector(getFiltros);
  const { empresa, roteiro, perfil } = useSelector(getUsuario);
  const { data: dataEmpresas } = useSelector(getDominioEmpresas);
  const { data: dataDispositivos } = useSelector(getDominioDispositivos);
  const { data: dataGrupos } = useSelector(getDominioGrupos);
  const [showRoteiro, setShowRoteiro] = useState(false);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirFiltros(false));
  }, [dispatch]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (f) => ({
      inicioPeriodo: validators.date({
        required: true,
        custom: {
          small: (value) => {
            const fim = f.getValues("fimPeriodo");
            const r1 = formatters.dates.revert(value);
            const r2 = formatters.dates.revert(fim);

            return (
              r1.localeCompare(r2) <= 0 ||
              "Valor não deve ser maior que Data Fim"
            );
          },
        },
      }),
      fimPeriodo: validators.date({
        required: true,
        custom: {
          large: (value) => {
            const inicio = f.getValues("inicioPeriodo");
            const r1 = formatters.dates.revert(value);
            const r2 = formatters.dates.revert(inicio);

            return (
              r1.localeCompare(r2) >= 0 ||
              "Valor não deve ser menor que Data Início"
            );
          },
        },
      }),
      minCheckpoints: validators.number({
        required: false,
        custom: {
          _100: (value) =>
            !value || value <= 100 || "Valor não pode ser maior do que 100",
          small: (value) => {
            const max = f.getValues("maxCheckpoints");
            return (
              !value ||
              !max ||
              value <= max ||
              "Valor não pode ser maior do que % Checkpoints Máx."
            );
          },
        },
      }),
      maxCheckpoints: validators.number({
        required: false,
        custom: {
          _100: (value) =>
            !value || value <= 100 || "Valor não pode ser maior do que 100",
          small: (value) => {
            const min = f.getValues("minCheckpoints");
            return (
              !value ||
              !min ||
              value >= min ||
              "Valor não pode ser menor do que % Checkpoints Min."
            );
          },
        },
      }),
      horarioInicio: validators.string({
        required: false,
        custom: {
          requiredIfOther: (value) => {
            if (!value) return true;
            if (value.length !== 5) {
              return "Horário inválido";
            }

            let hour = Number(value.slice(0, 2));
            let minute = Number(value.slice(3, 5));

            if (hour < 0 || hour > 23) {
              return "Horário inválido";
            }
            if (minute < 0 || minute > 59) {
              return "Horário inválido";
            }
            const max = f.getValues("horarioTermino");

            if (!!max) {
              if (value.localeCompare(max) > 0) {
                return "Não pode ser maior que o Horário Início (Até)";
              }
            }

            return true;
          },
        },
      }),
      horarioTermino: validators.string({
        required: false,
        custom: {
          requiredIfOther: (value) => {
            if (!value) return true;
            if (value.length !== 5) {
              return "Horário inválido";
            }

            let hour = Number(value.slice(0, 2));
            let minute = Number(value.slice(3, 5));

            if (hour < 0 || hour > 23) {
              return "Horário inválido";
            }
            if (minute < 0 || minute > 59) {
              return "Horário inválido";
            }

            const min = f.getValues("horarioInicio");

            if (!!min) {
              if (value.localeCompare(min) < 0) {
                return "Não pode ser menor que o Horário Início (De)";
              }
            }
            return true;
          },
        },
      }),
    }),
    initialValues: () => ({
      inicioPeriodo: filtros.inicioPeriodo,
      fimPeriodo: filtros.fimPeriodo,
      empresa: dataEmpresas.find((el) => el.id === empresa) ?? null,
      os: "",
      roteiro: "",
      dispositivo: null,
      grupo: null,
      minCheckpoints: "",
      maxCheckpoints: "",
      horarioInicio: "",
      horarioTermino: "",
      funcionamento: [],
      ordem: "inicio",
    }),
    handleSubmit: (values) => {
      dispatch(actions.atualizarFiltros({ ...values, page: 1 }));
      handleClose();
    },
  });

  const { reset, setValue } = formProps;

  const handleChangeEmpresa = useCallback(
    (item) => {
      setValue("grupo", null);
      if (item === null) {
        dispatch(actions.carregarGrupos.success({ response: { data: [] } }));
        setShowRoteiro(true);
      } else {
        dispatch(actions.carregarGrupos.request({ id: item.id }));
        if (item.roteiro === "N") {
          setShowRoteiro(false);
        } else {
          setShowRoteiro(true);
        }
      }
    },
    [dispatch, setValue]
  );

  useEffect(() => {
    if (!!filtersVisible && !!filtros) {
      reset(filtros);
    }
  }, [filtros, reset, filtersVisible]);

  useEffect(() => {
    if (dataEmpresas.find((el) => el.id === empresa)) {
      dispatch(
        actions.atualizarFiltros({
          empresa: dataEmpresas.find((el) => el.id === empresa),
        })
      );
    }
    // eslint-disable-next-line
  }, [empresa, dataEmpresas]);

  useEffect(() => {
    if (empresa === null) {
      dispatch(actions.atualizarFiltros());
    }
    // eslint-disable-next-line
  }, [empresa]);

  useEffect(() => {
    setShowRoteiro(!empresa ? true : roteiro === "S" ? true : false);
    // eslint-disable-next-line
  }, [empresa]);

  return (
    <FiltersDrawer
      handleClose={handleClose}
      visible={filtersVisible}
      formProps={formProps}
      handleSubmit={handleSubmit}
      handleChangeEmpresa={handleChangeEmpresa}
      domainEmpresas={dataEmpresas}
      domainDispositivos={dataDispositivos}
      domainGrupo={dataGrupos}
      showEmpresa={!empresa}
      showRoteiro={showRoteiro}
      perfil={perfil}
    />
  );
};

export default FilterDrawerConnected;
