import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components/loaders";
import { actions as mapaActions } from "../../../../reducers/mapa.actions";
import { actions } from "../../../../reducers/painelAlerta.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../../reducers/rotas.actions";

import {
  getFiltros,
  getOrder,
  getItems,
  isLoadingItems,
  getPageSize,
  getPage,
  getTotalItems,
} from "../../../../selectors/painelAlerta.selectors";
import AlertsTable from "./AlertsTable";

const AlertsTableConnected = () => {
  const filtros = useSelector(getFiltros);
  const items = useSelector(getItems);
  const loading = useSelector(isLoadingItems);
  const order = useSelector(getOrder);
  const pageSize = useSelector(getPageSize);
  const total = useSelector(getTotalItems);
  const paginaAtual = useSelector(getPage);

  const dispatch = useDispatch();

  const handleOrderClick = useCallback(
    (field) => {
      dispatch(actions.atualizarOrdem(field));
    },
    [dispatch]
  );

  const handlePagination = (e, page) => {
    dispatch(
      dispatch(
        actions.atualizarFiltros({ ...filtros, page: page, pageSize: pageSize })
      )
    );
  };

  const handleGoToMap = (values) => {
    dispatch(mapaActions.alternarBotao("alerta"));
    dispatch(mapaActions.atualizarSelecionarOs.request({ id: values }));
    dispatch(routeActions.redirectTo(routes.MAPA));
  };

  if (loading) {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <Loader />
      </div>
    );
  }

  return (
    <AlertsTable
      cliente={
        filtros?.empresa?.nome ? filtros?.empresa?.nome : filtros.empresaNome
      }
      data={items}
      order={order}
      handleOrderClick={handleOrderClick}
      handleGoToMap={handleGoToMap}
      handlePagination={handlePagination}
      pageSize={pageSize}
      total={total}
      paginaAtual={paginaAtual}
    />
  );
};

export default AlertsTableConnected;
