import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components/loaders";
import { actions as mapaActions } from "../../../../reducers/mapa.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../../reducers/rotas.actions";

import {
  getFiltrosChart,
  getChartItems,
  isLoadingChartItems,
} from "../../../../selectors/painelAlerta.selectors";
import AlertsChart from "./AlertsChart";

const AlertsChartConnected = () => {
  const filtros = useSelector(getFiltrosChart);
  const items = useSelector(getChartItems);
  const loading = useSelector(isLoadingChartItems);

  const dispatch = useDispatch();

  const handleGoToMap = (values) => {
    dispatch(mapaActions.alternarBotao("alerta"));
    dispatch(mapaActions.atualizarSelecionarOs.request({ id: values }));
    dispatch(routeActions.redirectTo(routes.MAPA));
  };

  if (loading) {
    return (
      <div style={{ width: "100%", textAlign: "center" }}>
        <Loader />
      </div>
    );
  }

  return (
    <AlertsChart
      cliente={
        filtros?.empresa?.nome ? filtros?.empresa?.nome : filtros.empresaNome
      }
      data={items}
      handleGoToMap={handleGoToMap}
    />
  );
};

export default AlertsChartConnected;
