import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../reducers/painelAlerta.actions";
import {
  getFiltros,
  getOs,
} from "../../../../selectors/painelAlerta.selectors";
import HeaderAlertsChart from "./HeaderAlertsChart";

const HeaderAlertsChartConnected = () => {
  const dispatch = useDispatch();
  const filtros = useSelector(getFiltros);
  const osFile = useSelector(getOs);

  const handleClickOpenFilters = useCallback(() => {
    dispatch(actions.exibirFiltrosChart(true));
  }, [dispatch]);

  const handleDownloadCsv = useCallback(() => {
    dispatch(actions.downloadOs.request());
  }, [dispatch]);

  const handleCleanOsData = useCallback(() => {
    dispatch(actions.cleanOsData());
  }, [dispatch]);

  return (
    <HeaderAlertsChart
      handleDownloadCsv={handleDownloadCsv}
      cliente={
        filtros?.empresa?.nome ? filtros?.empresa?.nome : filtros.empresaNome
      }
      inicioPeriodo={filtros.inicioPeriodo}
      fimPeriodo={filtros.fimPeriodo}
      handleClickOpenFilters={handleClickOpenFilters}
      loading={osFile.loading}
      osFile={osFile}
      handleCleanOsData={handleCleanOsData}
    />
  );
};

export default HeaderAlertsChartConnected;
