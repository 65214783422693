import React, { useCallback, useEffect } from "react";
import FiltersDrawer from "./FiltersDrawer";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../reducers/painelAlerta.actions";
import {
  getDominioDispositivos,
  getDominioEmpresas,
  getDrawer,
  getFiltros,
} from "../../../../selectors/painelAlerta.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import { getUsuario } from "../../../../selectors/usuario.selectors";
import validators from "../../../../utils/validators";
import formatters from "../../../../utils/formatters";

const FilterDrawerConnected = () => {
  const dispatch = useDispatch();
  const { filters: filtersVisible } = useSelector(getDrawer);
  const filtros = useSelector(getFiltros);
  const { empresa } = useSelector(getUsuario);
  const { data: dataEmpresas } = useSelector(getDominioEmpresas);
  const { data: dataDispositivos } = useSelector(getDominioDispositivos);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirFiltros(false));
  }, [dispatch]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (f) => ({
      inicioPeriodo: validators.date({
        required: true,
        custom: {
          small: (value) => {
            const fim = f.getValues("fimPeriodo");
            const r1 = formatters.dates.revert(value);
            const r2 = formatters.dates.revert(fim);

            return (
              r1.localeCompare(r2) <= 0 ||
              "Valor não deve ser maior que Data Fim"
            );
          },
        },
      }),
      fimPeriodo: validators.date({
        required: true,
        custom: {
          large: (value) => {
            const inicio = f.getValues("inicioPeriodo");
            const r1 = formatters.dates.revert(value);
            const r2 = formatters.dates.revert(inicio);

            return (
              r1.localeCompare(r2) >= 0 ||
              "Valor não deve ser menor que Data Início"
            );
          },
        },
      }),
      empresa: validators.string({
        required: false,
      }),
    }),
    initialValues: () => ({
      inicioPeriodo: filtros.inicioPeriodo,
      fimPeriodo: filtros.fimPeriodo,
      empresa: dataEmpresas.find((el) => el.id === empresa) ?? null,
      roteiro: "",
      dispositivo: null,
      ordem: "inicio",
    }),
    handleSubmit: (values) => {
      dispatch(actions.atualizarFiltros({ ...values, page: 1 }));
      handleClose();
    },
  });

  const { reset, setValue } = formProps;
  /*
  const handleChangeEmpresa = useCallback(
    (item) => {
      setValue("nomeAlerta", null);
      if (item !== null) {
        dispatch(actions.carregarRegras.request({ id: item.id }));
      }
    },
    [dispatch, setValue]
  );
*/
  useEffect(() => {
    if (!!filtersVisible && !!filtros) {
      reset(filtros);
    }
  }, [filtros, reset, filtersVisible]);

  useEffect(() => {
    if (dataEmpresas.find((el) => el.id === empresa)) {
      dispatch(
        actions.atualizarFiltros({
          empresa: dataEmpresas.find((el) => el.id === empresa),
        })
      );
    }
    // eslint-disable-next-line
  }, [empresa, dataEmpresas]);

  useEffect(() => {
    if (empresa === null) {
      dispatch(actions.atualizarFiltros());
    }
    // eslint-disable-next-line
  }, [empresa]);

  return (
    <FiltersDrawer
      handleClose={handleClose}
      visible={filtersVisible}
      formProps={formProps}
      handleSubmit={handleSubmit}
      //     handleChangeEmpresa={handleChangeEmpresa}
      showEmpresa={!empresa}
      domainEmpresas={dataEmpresas}
      domainDispositivos={dataDispositivos}
      empresa={empresa}
    />
  );
};

export default FilterDrawerConnected;
