import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  ATUALIZAR_FILTROS: "@@painelAlerta/ATUALIZAR_FILTROS",
  EXIBIR_FILTROS: "@@painelAlerta/EXIBIR_FILTROS",
  ATUALIZAR_FILTROS_CHART: "@@painelAlerta/ATUALIZAR_FILTROS_CHART",
  EXIBIR_FILTROS_CHART: "@@painelAlerta/EXIBIR_FILTROS_CHART",
  ATUALIZAR_FILTROS_TOP: "@@painelAlerta/ATUALIZAR_FILTROS_TOP",
  EXIBIR_FILTROS_TOP: "@@painelAlerta/EXIBIR_FILTROS_TOP",
  SELECIONAR_OS: "@@painelAlerta/SELECIONAR_OS",
  PESQUISAR: "@@painelAlerta/PESQUISAR",
  PESQUISAR_TOP_ALERTS: "@@painelAlerta/PESQUISAR_TOP_ALERTS",
  PESQUISAR_CHART: "@@painelAlerta/PESQUISAR_CHART",
  UPDATE_ORDER: "@@painelAlerta/UPDATE_ORDER",
  DOMINIO_EMPRESAS: "@@painelAlerta/DOMINIO_EMPRESAS",
  DOMINIO_DISPOSITIVOS: "@@painelAlerta/DOMINIO_DISPOSITIVOS",
  UPDATE_PAGE_SIZE: "@@painelAlerta/UPDATE_PAGE_SIZE",
};

export const actions = {
  selecionarOs: (item) => createSyncAction(types.SELECIONAR_OS, { item }),
  atualizarFiltros: (filtros) =>
    createSyncAction(types.ATUALIZAR_FILTROS, { filtros }),
  exibirFiltros: (visible) =>
    createSyncAction(types.EXIBIR_FILTROS, { visible }),
  atualizarFiltrosChart: (filtros) =>
    createSyncAction(types.ATUALIZAR_FILTROS_CHART, { filtros }),
  exibirFiltrosChart: (visible) =>
    createSyncAction(types.EXIBIR_FILTROS_CHART, { visible }),
  atualizarFiltrosTop: (filtros) =>
    createSyncAction(types.ATUALIZAR_FILTROS_TOP, { filtros }),
  exibirFiltrosTop: (visible) =>
    createSyncAction(types.EXIBIR_FILTROS_TOP, { visible }),
  atualizarOrdem: (field) => createSyncAction(types.UPDATE_ORDER, { field }),
  atualizarTamanhoPagina: (pageSize) =>
    createSyncAction(types.UPDATE_PAGE_SIZE, { pageSize }),
  pesquisar: createAsyncAction(types.PESQUISAR),
  pesquisarTop: createAsyncAction(types.PESQUISAR_TOP_ALERTS),
  pesquisarChart: createAsyncAction(types.PESQUISAR_CHART),
  carregarEmpresas: createAsyncAction(types.DOMINIO_EMPRESAS),
  carregarDispositivos: createAsyncAction(types.DOMINIO_DISPOSITIVOS),
};
