import React, { useCallback, useEffect } from "react";
import FiltersTopDrawer from "./FiltersTopDrawer";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../reducers/painelAlerta.actions";
import {
  getDominioDispositivos,
  getDominioEmpresas,
  getDrawerTop,
  getFiltrosTop,
} from "../../../../selectors/painelAlerta.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import { getUsuario } from "../../../../selectors/usuario.selectors";
import validators from "../../../../utils/validators";

const FilterTopDrawerConnected = () => {
  const dispatch = useDispatch();
  const { filters: filtersVisible } = useSelector(getDrawerTop);
  const filtros = useSelector(getFiltrosTop);
  const { empresa } = useSelector(getUsuario);
  const { data: dataEmpresas } = useSelector(getDominioEmpresas);
  const { data: dataDispositivos } = useSelector(getDominioDispositivos);

  const handleClose = useCallback(() => {
    dispatch(actions.exibirFiltrosTop(false));
  }, [dispatch]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: (f) => ({
      referencia: validators.object({
        required: true,
      }),
      empresa: validators.string({
        required: false,
      }),
    }),
    initialValues: () => ({
      empresa: dataEmpresas.find((el) => el.id === empresa) ?? null,
      roteiro: "",
      dispositivo: null,
    }),
    handleSubmit: (values) => {
      dispatch(actions.atualizarFiltrosTop({ ...values, page: 1 }));
      handleClose();
    },
  });

  const { reset } = formProps;

  useEffect(() => {
    if (!!filtersVisible && !!filtros) {
      reset(filtros);
    }
  }, [filtros, reset, filtersVisible]);

  useEffect(() => {
    if (dataEmpresas.find((el) => el.id === empresa)) {
      dispatch(
        actions.atualizarFiltros({
          empresa: dataEmpresas.find((el) => el.id === empresa),
        })
      );
    }
    // eslint-disable-next-line
  }, [empresa, dataEmpresas]);

  useEffect(() => {
    if (empresa === null) {
      dispatch(actions.atualizarFiltros());
    }
    // eslint-disable-next-line
  }, [empresa]);

  return (
    <FiltersTopDrawer
      handleClose={handleClose}
      visible={filtersVisible}
      formProps={formProps}
      handleSubmit={handleSubmit}
      showEmpresa={!empresa}
      domainEmpresas={dataEmpresas}
      domainDispositivos={dataDispositivos}
      empresa={empresa}
    />
  );
};

export default FilterTopDrawerConnected;
