import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const AlertsChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={450}>
      <LineChart
        data={data}
        margin={{ top: 50, right: 50, left: 0, bottom: 30 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dia" angle={-45} textAnchor="end" height={60} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line
          name={"Roteiro com Atraso"}
          type="monotone"
          dataKey="Atraso"
          stroke="#2F85C2"
          strokeWidth={3}
          activeDot={{ r: 8 }}
        />
        <Line
          name={"Falta de Mensagem"}
          type="monotone"
          dataKey="Falta"
          stroke="#F24F13"
          strokeWidth={3}
          activeDot={{ r: 8 }}
        />
        <Line
          name={"Roteiro Parado"}
          type="monotone"
          dataKey="Parado"
          stroke="#38C546"
          strokeWidth={3}
          activeDot={{ r: 8 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AlertsChart;
