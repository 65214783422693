import { useDispatch, useSelector } from "react-redux";
import FormUsuarioPage from "./FormEditarUsuarioPage";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { getUsuario } from "../../../selectors/cadastro/usuarios.selectors";
import validators from "../../../utils/validators";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import { actions } from "../../../reducers/cadastro/usuarios.actions";
import { getClientesLista } from "../../../selectors/cadastro/clientes.selectors";

const FormEditarUsuarioPageConnected = () => {
  const { loading, data } = useSelector(getUsuario);
  const { data: dataCliente, loading: loadingCliente } = useSelector(
    getClientesLista
  );

  const dispatch = useDispatch();

  const rules = {
    nome: validators.string({ required: true }),
    email: validators.email({ required: true }),
    codigo: validators.string({ required: true }),
    situacao: validators.string({ required: true }),
    perfil: validators.string({ required: true }),
    empresa: validators.string({
      custom: {
        requiredIfOther: (value) => {
          const perfil = formProps.getValues("perfil");
          if ((perfil === "C" || perfil === "E" || perfil === "D") && !value) {
            return !!value || "Campo Obrigatório";
          } else {
            return true;
          }
        },
      },
    }),
  };

  const initialValues = {
    nome: "",
    email: "",
    codigo: "",
    situacao: "",
    perfil: "",
    empresa: "",
  };

  const formProps = {
    ...useForm({ defaultValue: { ...initialValues } }),
    rules,
    initialValues,
  };

  useEffect(() => {
    formProps.setValue("nome", data.nome);
    formProps.setValue("email", data.email);
    formProps.setValue("codigo", data.codigo);
    formProps.setValue("situacao", data?.situacao ?? "A");
    formProps.setValue("perfil", data?.perfil ?? "A");
    formProps.setValue("empresa", data?.empresa ?? "");
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    formProps.setValue("empresa", data?.empresa);
    // eslint-disable-next-line
  }, [data, dataCliente]);

  const handleSubmit = () => {
    const id = data.id;
    const nome = formProps.getValues("nome");
    const email = formProps.getValues("email");
    const codigo = formProps.getValues("codigo");
    const situacao = formProps.getValues("situacao");
    const perfil = formProps.getValues("perfil");
    const empresa = formProps.getValues("empresa");
    console.log(empresa);

    dispatch(
      actions.editarUsuario.request({
        id,
        nome,
        email,
        codigo,
        situacao,
        perfil,
        empresa,
      })
    );
  };

  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.CADASTRO_USUARIO));
  };

  return (
    <FormUsuarioPage
      handleSubmit={handleSubmit}
      handleGoBack={handleClickVoltar}
      formProps={formProps}
      data={data}
      loading={loading}
      dataCliente={dataCliente}
      loadingCliente={loadingCliente}
      usuario={data}
    />
  );
};

export default FormEditarUsuarioPageConnected;
