import { FilterAltOutlined } from "@mui/icons-material";
import { styled, Tooltip } from "@mui/material";
import React from "react";
import RoundIconButton from "../../../../components/buttons/RoundIconButton";

const StyledCardTitle = styled("h1")`
  font-family: Oswald;
  font-size: 32px;
  font-weight: 300;
  line-height: 1.2;
  letter-spacing: -0.25px;
  text-align: left;
  margin-right: 10px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const StyledClientTitle = styled("h1")`
  font-family: Oswald;
  font-size: 22px;
  font-weight: 300;
  line-height: 10px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
`;

const StyledHeaderContainer = styled("div")`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const StyledEndContainer = styled("div")`
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
`;
const StyledStartContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
`;

function AlertsChart({
  inicioPeriodo,
  fimPeriodo,
  handleClickOpenFilters,
  cliente,
}) {
  return (
    <StyledHeaderContainer>
      <StyledStartContainer>
        <StyledCardTitle>Evolução nos últimos 30 dias</StyledCardTitle>
      </StyledStartContainer>
      <StyledEndContainer>
        <RoundIconButton
          handleClick={(e) => {
            handleClickOpenFilters();
          }}
        >
          <Tooltip title="Filtros">
            <FilterAltOutlined sx={{ fill: "#51a8d1" }} />
          </Tooltip>
        </RoundIconButton>
        <StyledClientTitle>{cliente}</StyledClientTitle>
      </StyledEndContainer>
    </StyledHeaderContainer>
  );
}

export default AlertsChart;
