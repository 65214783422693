import { createSelector } from "reselect";

export const getPainelAlerta = (state) => state.painelAlerta;
export const getDominios = createSelector(
  getPainelAlerta,
  (state) => state.dominios
);

export const getFiltrosChart = createSelector(
  getPainelAlerta,
  (state) => state.filtrosChart
);

export const getFiltrosTop = createSelector(
  getPainelAlerta,
  (state) => state.filtrosTop
);

export const getFiltros = createSelector(
  getPainelAlerta,
  (state) => state.filtros
);

export const getDrawerChart = createSelector(
  getPainelAlerta,
  (state) => state.drawerChart
);

export const getDrawerTop = createSelector(
  getPainelAlerta,
  (state) => state.drawerTop
);

export const getDrawer = createSelector(
  getPainelAlerta,
  (state) => state.drawer
);
export const getOrder = createSelector(getPainelAlerta, (state) => state.order);

export const getDominioEmpresas = createSelector(
  getDominios,
  (state) => state.empresas
);
export const getDominioDispositivos = createSelector(
  getDominios,
  (state) => state.dispositivos
);

export const isLoadingItems = createSelector(
  getPainelAlerta,
  (state) => state.loading
);

export const isLoadingTopItems = createSelector(
  getPainelAlerta,
  (state) => state.topLoading
);

export const isLoadingChartItems = createSelector(
  getPainelAlerta,
  (state) => state.chartLoading
);

export const isUpdatingItem = createSelector(
  getPainelAlerta,
  (state) => state.updating
);
export const getItems = createSelector(getPainelAlerta, (state) => state.items);

export const getChartItems = createSelector(
  getPainelAlerta,
  (state) => state.chartItems
);
export const getTopItems = createSelector(
  getPainelAlerta,
  (state) => state.topItems
);

export const getTotalItems = createSelector(
  getPainelAlerta,
  (state) => state.total
);
export const getPageSize = createSelector(
  getPainelAlerta,
  (state) => state.pageSize
);
export const getPage = createSelector(getPainelAlerta, (state) => state.page);

export const getOs = createSelector(getPainelAlerta, (state) => state.os);
