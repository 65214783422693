import { Drawer, Grid, IconButton, MenuItem, styled } from "@mui/material";
import React from "react";
import { transientOptions } from "../../../styles/theme";
import { Title } from "../../../components/styled";
import { HighlightOff } from "@mui/icons-material";
import {
  ControlledTextField,
  DateTextField,
  IntegerTextField,
} from "../../../components/inputs";
import { Button } from "../../../components";
import { funcionamento, ordemPesquisa } from "../constants/mapa.constants";
import ControlledAutocomplete from "../../../components/inputs/ControlledAutocomplete";
import HourTextField from "../../../components/inputs/HourTextField";

const StyledDrawer = styled(Drawer, transientOptions)`
  & .MuiDrawer-paper {
    box-shadow: ${({ theme }) => theme.shadow.map.options};
    max-width: 500px;
    width: 100%;
  }
`;
const TitleWithoutMargins = styled(Title)`
  margin: 0 0 1.5rem 0 !important;
`;
const CloseIconButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  color: ${({ theme }) => theme.colors.dark};
`;

const GradientButton = styled(Button)`
  background: ${({ theme }) => theme.colors.gradients.button};
  border-radius: 100px;
`;
const OutlinedButton = styled(Button)`
  background: transparent;
  border: 1px solid ${({ theme }) => theme.colors.primary._700};
  color: ${({ theme }) => theme.colors.primary._700};
  border-radius: 100px;

  &:hover {
    background: transparent;
  }
`;

const FiltersDrawer = ({
  handleClose,
  visible,
  formProps,
  handleChangeEmpresa,
  domainEmpresas,
  domainDispositivos,
  domainGrupo,
  handleSubmit,
  showEmpresa,
  showRoteiro,
  perfil,
}) => {
  return (
    <StyledDrawer onClose={handleClose} open={visible} anchor={"right"}>
      <CloseIconButton onClick={handleClose}>
        <HighlightOff />
      </CloseIconButton>
      <form onSubmit={handleSubmit}>
        <Grid container padding={"1rem"} spacing={"1rem"}>
          <Grid item xs={12}>
            <TitleWithoutMargins>Filtros</TitleWithoutMargins>
          </Grid>
          <Grid item xs={6} lg={6}>
            <ControlledTextField
              name={"inicioPeriodo"}
              formProps={formProps}
              label={"Data Início"}
              disabled={perfil==="D"}
              InputProps={{
                inputComponent: DateTextField,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledTextField
              name={"fimPeriodo"}
              formProps={formProps}
              disabled={perfil==="D"}
              label={"Data Fim"}
              InputProps={{
                inputComponent: DateTextField,
              }}
            />
          </Grid>
          {!showEmpresa && domainEmpresas.length === 1 ? (
            <></>
          ) : (
            <Grid item xs={12}>
              <ControlledAutocomplete
                name={"empresa"}
                formProps={formProps}
                label={"Cliente"}
                autocompleteProps={{
                  onChange: handleChangeEmpresa,
                }}
                domain={domainEmpresas}
                labelField={"nome"}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ControlledTextField
              name={"os"}
              formProps={formProps}
              label={"Ordem de Serviço"}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name={"roteiro"}
              formProps={formProps}
              label={"Roteiro"}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              name={"dispositivo"}
              formProps={formProps}
              label={"Dispositivo"}
              domain={domainDispositivos}
              labelField={"nome"}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledAutocomplete
              name={"grupo"}
              disabled={domainGrupo.length === 0}
              formProps={formProps}
              label={"Grupo"}
              domain={domainGrupo}
              labelField={"descricao"}
            />
          </Grid>
          {showRoteiro && (
            <>
              <Grid item xs={6}>
                <ControlledTextField
                  name={"minCheckpoints"}
                  formProps={formProps}
                  label={"% Checkpoints Mín. "}
                  InputProps={{
                    inputComponent: IntegerTextField,
                  }}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
              <Grid item xs={6}>
                <ControlledTextField
                  name={"maxCheckpoints"}
                  formProps={formProps}
                  label={"% Checkpoints Máx. "}
                  InputProps={{
                    inputComponent: IntegerTextField,
                  }}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <ControlledAutocomplete
              name={"funcionamento"}
              formProps={formProps}
              label={"Funcionamento"}
              domain={funcionamento}
              idField={"value"}
              labelField={"label"}
              autocompleteProps={{
                multiple: true,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledTextField
              name={"horarioInicio"}
              formProps={formProps}
              label={"Horário Início (De) "}
              InputProps={{
                inputComponent: HourTextField,
              }}
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
          <Grid item xs={6}>
            <ControlledTextField
              name={"horarioTermino"}
              formProps={formProps}
              label={"Horário Início (Até) "}
              InputProps={{
                inputComponent: HourTextField,
              }}
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
          <Grid item xs={12}>
            <ControlledTextField
              name={"ordem"}
              label={"Ordenar por"}
              formProps={formProps}
              select
            >
              {showRoteiro &&
                ordemPesquisa.map((o) => (
                  <MenuItem key={o.value} value={o.value}>
                    {o.label}
                  </MenuItem>
                ))}
              {!showRoteiro &&
                ordemPesquisa
                  .filter((el) => el.label !== "% Progresso")
                  .map((o) => (
                    <MenuItem key={o.value} value={o.value}>
                      {o.label}
                    </MenuItem>
                  ))}
            </ControlledTextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <OutlinedButton onClick={handleClose}>VOLTAR</OutlinedButton>
          </Grid>
          <Grid item xs={12} md={6}>
            <GradientButton type={"submit"}>FILTRAR</GradientButton>
          </Grid>
        </Grid>
      </form>
    </StyledDrawer>
  );
};

export default FiltersDrawer;
