import { Card, styled } from "@mui/material";
import React from "react";
import { Title } from "../../components/styled";
import BasePage from "../BasePage";
import FiltersDrawer from "./components/alertsTable/FiltersDrawer.connected";
import Header from "./components/alertsTable/Header.connected";
import AlertsTable from "./components/alertsTable/AlertsTable.connected";
import HeaderTopAlerts from "./components/topAlerts/HeaderTopAlerts.connected";
import TopAlerts from "./components/topAlerts/TopAlerts.connected";
import HeaderAlertsChart from "./components/alertsChart/HeaderAlertsChart.connected";
import AlertsChart from "./components/alertsChart/AlertsChart.connected";
import FiltersChartDrawer from "./components/alertsChart/FiltersChartDrawer.connected";
import FiltersTopDrawer from "./components/topAlerts/FiltersTopDrawer.connected";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  display: flex;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
  padding-right: 5px;
  overflow: auto;
`;
const StyledContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-flow: column wrap;
`;

const StyledForm = styled("div")`
  max-width: 100%;
`;

const StyledOutsideContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

function MapPage() {
  return (
    <BasePage isLoggedIn={true}>
      <Title>Painel de Alertas</Title>
      <StyledOutsideContainer>
        <StyledForm>
          <StyledCard>
            <StyledContainer>
              <Header />
              <AlertsTable />
            </StyledContainer>
            <FiltersDrawer />
          </StyledCard>
        </StyledForm>
      </StyledOutsideContainer>
      <StyledOutsideContainer>
        <StyledForm>
          <StyledCard>
            <StyledContainer>
              <HeaderTopAlerts />
              <TopAlerts />
            </StyledContainer>
            <FiltersTopDrawer />
          </StyledCard>
        </StyledForm>
      </StyledOutsideContainer>
      <StyledOutsideContainer>
        <StyledForm>
          <StyledCard>
            <StyledContainer>
              <HeaderAlertsChart />
              <AlertsChart />
            </StyledContainer>
            <FiltersChartDrawer />
          </StyledCard>
        </StyledForm>
      </StyledOutsideContainer>
    </BasePage>
  );
}

export default MapPage;
