import React, { useState } from "react";
import {
  Card,
  Grid,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import formatters from "../../../../utils/formatters";
import { ArrowDropDown, ArrowDropUp, InfoOutlined } from "@mui/icons-material";
import { tipoAlerta } from "../../../../utils/parametros";
import Popover from "@mui/material/Popover";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-top: 10px;
  margin-bottom: 20px;
`;
const StyledTableRow = styled(TableRow)`
  padding: 0;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #dbe4e6;
    max-height: 10px;
    color: #ffffff !important;
  }
  &:nth-of-type(even) {
    background-color: #eff1f2;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.family.default};
  border-bottom: none !important;
`;
const StyledTitleTableCell = styled(TableCell)`
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.family.default};
  border-bottom: none !important;
  cursor: pointer;
`;
const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: 100%;
  width: 100%;
  margin: auto auto 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: none;
  padding: 2rem 1.5rem 1.5rem;
`;
const StyledPagination = styled(Pagination)`
  & .MuiButtonBase-root {
    background-color: #ffffff !important;
    color: #444746;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  }

  & .Mui-selected {
    color: #ffffff;
    background-color: #2f85c2 !important;
    box-shadow: none;
  }
`;
const StyledGridPagination = styled(Grid)`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;
const StyledGridPDownload = styled(Grid)`
  display: flex;
  justify-content: end;
  margin-top: -30px;
`;
const Arrow = styled(ArrowDropDown)`
  vertical-align: bottom;
`;

const ExecucaoTable = ({
  data,
  total,
  pageSize,
  handlePagination,
  order,
  handleOrderClick,
  paginaAtual,
  handleGoToMap,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dadosAlerta, setDadosAlerta] = useState("");

  const handleClick = (event, alerta) => {
    setAnchorEl(event.currentTarget);
    setDadosAlerta(alerta);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <StyledTable>
        <TableBody>
          <StyledTableRow>
            <StyledTitleTableCell
              onClick={() => handleOrderClick("dataHoraCriacao")}
            >
              Data/Hora
              <OrderIcon order={order} field={"dataHoraCriacao"} />
            </StyledTitleTableCell>
            <StyledTitleTableCell onClick={() => handleOrderClick("empresa")}>
              Cliente
              <OrderIcon order={order} field={"empresa"} />
            </StyledTitleTableCell>
            <StyledTitleTableCell onClick={() => handleOrderClick("roteiro")}>
              Roteiro
              <OrderIcon order={order} field={"roteiro"} />
            </StyledTitleTableCell>
            <StyledTitleTableCell
              onClick={() => handleOrderClick("dispositivo.nome")}
            >
              Dispositivo
              <OrderIcon order={order} field={"dispositivo.nome"} />
            </StyledTitleTableCell>
            <StyledTitleTableCell
              onClick={() => handleOrderClick("tipoAlerta")}
            >
              Tipo de Alerta
              <OrderIcon order={order} field={"tipoAlerta"} />
            </StyledTitleTableCell>
            <StyledTitleTableCell>Detalhes</StyledTitleTableCell>
          </StyledTableRow>
          {data.map((item, index) => {
            return (
              <StyledTableRow key={index}>
                <StyledTableCell
                  onClick={() => {
                    handleGoToMap(item.os);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {formatters.dates.withHours(new Date(item.alerta.dataAlerta))}
                </StyledTableCell>
                <StyledTableCell>{item.empresaAbreviado}</StyledTableCell>
                <StyledTableCell>{item.roteiro}</StyledTableCell>
                <StyledTableCell>{item.dispositivo}</StyledTableCell>
                <StyledTableCell>
                  {tipoAlerta.filter((a) => a.id === item.tipo)[0].nome}
                </StyledTableCell>
                {item.alerta.tipo=== 'RA' && <StyledTableCell>
                  {formatters.numbers.simple(item.percentualExecutado)}% /{" "}
                  {formatters.numbers.simple(
                    item.alerta.percentualEvolucaoAbaixo
                  )}
                  % - {item.alerta.minutosEvolucao}min
                  <InfoOutlined
                    onClick={(event) => handleClick(event, item)}
                    style={{
                      marginLeft: "10px",
                      verticalAlign: "middle",
                      cursor: "pointer",
                      fontSize: "1rem",
                    }}
                  />
                </StyledTableCell>}
                {item.alerta.tipo==='FM' && <StyledTableCell>
                  {formatters.numbers.simple(item.alerta.maximoHorasSemMensagem)} hora
                </StyledTableCell>}
                {item.alerta.tipo==='RP' && <StyledTableCell>
                  {formatters.numbers.simple(item.alerta.minutosParado)} minutos
                </StyledTableCell>}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </StyledTable>
      <StyledGridPagination>
        <StyledPagination
          count={Math.ceil(total / pageSize)}
          page={paginaAtual}
          variant="outlined"
          shape="rounded"
          onChange={handlePagination}
        />
      </StyledGridPagination>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography sx={{ p: 1 }}>
          <b>Percentual Esperado</b>:{" "}
          {formatters.numbers.simple(
            dadosAlerta?.alerta?.percentualEvolucaoAbaixo
          )}
          %
        </Typography>
        <Typography sx={{ p: 1 }}>
          <b>Percentual Executado Real</b>:{" "}
          {formatters.numbers.simple(dadosAlerta?.percentualExecutado)}%
        </Typography>
        <Typography sx={{ p: 1 }}>
          <b>Minutos Transcorridos</b>:{" "}
          {formatters.numbers.simple(dadosAlerta?.minutosEvolucao)} minutos
        </Typography>
      </Popover>
    </>
  );
};

const OrderIcon = ({ order, field }) => {
  if (!order) {
    return null;
  }
  if (order.field === field) {
    if (order.direction === "asc") {
      return <Arrow as={ArrowDropDown} />;
    } else {
      return <Arrow as={ArrowDropUp} />;
    }
  }

  return null;
};

export default ExecucaoTable;
