import BasePage from "../../BasePage";
import React, { useState } from "react";
import { Card, MenuItem, Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import {
  ControlledCustomTextField,
  CustomControlledAutoComplete,
} from "../../../components/inputs";
import { Button } from "../../../components";
import { useEffect } from "react";

const StyledCard = styled(Card)`
  background-color: #dbe4e6;
  height: auto;
  width: auto;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px !important;
  padding: 25px;
`;
const StyledControlledCustomTextField = styled(ControlledCustomTextField)``;

const StyledAtualizarButton = styled(Button)`
  background: linear-gradient(92.97deg, #2f85c2 44.56%, #8ebecd 96.62%);
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 576px) {
    width: 100%;
  }
`;
const StyledVoltarButton = styled(Button)`
  margin-top: 20px;
  background: #ffffff !important;
  border: 1px solid #2f85c2;
  color: #2f85c2;
  height: 40px;
  width: 276px;
  border-radius: 100px;
  padding: 10px, 24px, 10px, 24px;
  margin-bottom: auto;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const StyledGridContainer = styled(Grid)``;

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: center;
`;
const ButtonContainerLeft = styled("div")`
  display: flex;
  margin-left: 0px;
  justify-content: center;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 370px;
  margin-bottom: -65px;
  margin-top: -70px;
  border-radius: 20px;
`;

const StyledForm = styled("form")`
  max-width: 100%;
`;

const StyledTitle = styled("h1")`
  font-family: Oswald;
  font-size: 57px;
  font-weight: 400;
  line-height: 64px;
  letter-spacing: -0.25px;
  text-align: left;
  margin-top: 25px;

  @media (max-width: 576px) {
    margin-left: 20px;
    margin-top: 10px;
    font-size: 43px;
  }
`;

const FormEditarUsuarioPage = ({
  usuario,
  loading,
  formProps,
  handleSubmit,
  handleGoBack,
  loadingCliente,
  dataCliente,
}) => {
  const [showClientes, setShowClientes] = useState(false);

  const handleShowClientes = (value) => {
    if (value === "C" || value === "E" || value === "D") {
      setShowClientes(true);
    } else {
      setShowClientes(false);
      formProps.setValue("empresa", null);
    }
  };

  useEffect(() => {
    if (usuario?.perfil === "C" || usuario?.perfil === "E" || usuario?.perfil === "D") {
      setShowClientes(true);
    }
  }, [usuario]);

  if (loading) {
    return (
      <BasePage isLoggedIn={true}>
        <StyledTitle>Editar Usuário</StyledTitle>
        <StyledSkeleton />
      </BasePage>
    );
  }

  return (
    <BasePage>
      <StyledTitle>Editar Usuário</StyledTitle>
      <StyledForm onSubmit={formProps.handleSubmit(handleSubmit)}>
        <StyledCard>
          <StyledGridContainer container>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="codigo"
                label="Usuário"
                inputProps={{ maxLength: 60 }}
              />
            </StyledGrid>

            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="email"
                label="Email"
                inputProps={{ maxLength: 100 }}
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={12} md={6}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="nome"
                label="Nome"
                inputProps={{ maxLength: 100 }}
              />
            </StyledGrid>

            <StyledGrid item xs={12} sm={3} md={3}>
              <StyledControlledCustomTextField
                onChange={(e) => handleShowClientes(e.target.value)}
                formProps={formProps}
                fullWidth
                name="perfil"
                label="Perfil"
                select
              >
                <MenuItem key={"A"} value={"A"}>
                  Administrador
                </MenuItem>
                <MenuItem key={"C"} value={"C"}>
                  Cliente
                </MenuItem>
                <MenuItem key={"D"} value={"D"}>
                  Dash
                </MenuItem>
                <MenuItem key={"E"} value={"E"}>
                  Usuário final
                </MenuItem>
              </StyledControlledCustomTextField>
            </StyledGrid>

            <StyledGrid item xs={12} sm={3} md={3}>
              <StyledControlledCustomTextField
                formProps={formProps}
                fullWidth
                name="situacao"
                label="Situação"
                select
              >
                <MenuItem key={"A"} value={"A"}>
                  Ativo
                </MenuItem>
                <MenuItem key={"I"} value={"I"}>
                  Inativo
                </MenuItem>
                <MenuItem key={"T"} value={"T"}>
                  Bloqueado por senha
                </MenuItem>
              </StyledControlledCustomTextField>
            </StyledGrid>
            {showClientes && dataCliente.length > 0 && (
              <StyledGrid item xs={12} sm={12} md={6}>
                <CustomControlledAutoComplete
                  formProps={formProps}
                  fullWidth
                  name="empresa"
                  label="Cliente"
                  labelField={"nome"}
                  idField={"id"}
                  loading={loadingCliente.toString()}
                  domain={dataCliente}
                ></CustomControlledAutoComplete>
              </StyledGrid>
            )}
          </StyledGridContainer>
        </StyledCard>
        <Grid justifyContent="center" container>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainer>
              <StyledVoltarButton onClick={handleGoBack}>
                Voltar
              </StyledVoltarButton>
            </ButtonContainer>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ButtonContainerLeft>
              <StyledAtualizarButton type={"submit"} loading={loading}>
                Atualizar
              </StyledAtualizarButton>
            </ButtonContainerLeft>
          </Grid>
        </Grid>
      </StyledForm>
    </BasePage>
  );
};

export default FormEditarUsuarioPage;
